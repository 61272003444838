<template>
  <div class="ng-content-wrap">
    <div class="ng-block-head ng-block-head-sm">
      <div class="ng-block-head-sub d-print-none">
        <router-link class="back-to" to="/orders">
          <em class="icon ni ni-chevron-left-c mr-1"></em>
          <span>All Orders</span>
        </router-link>
      </div>

      <div class="ng-block-between-md g-4">
        <div class="ng-block-head-content">
          <h3 class="ng-block-title fw-normal">
            {{ $route.meta.title }}
          </h3>

          <div class="ng-block-des">
            <h6>
              {{ fD(start) }}
            </h6>
          </div>
        </div>
        <div class="ng-block-head-content">
          <ul class="ng-block-tools gx-3 d-print-none">
            <li>
              <a
                href="#pickDates"
                data-toggle="modal"
                class="btn btn-white btn-dim btn-outline-primary"
              >
                <em class="icon ni ni-calendar"></em>
                <span>
                  <span class="d-none d-sm-inline-block"> Date </span>
                </span>
              </a>
              <div class="modal fade" tabindex="-1" id="pickDates">
                <div class="modal-dialog" role="document">
                  <div class="modal-content justify-content-center">
                    <button
                      id="hideNewForm"
                      class="close"
                      data-dismiss="modal"
                      aria-label="Close"
                    >
                      <em class="icon ni ni-cross text-white"></em>
                    </button>

                    <div class="modal-header bg-primary">
                      <h5 class="modal-title text-white">Select Date</h5>
                    </div>

                    <div class="modal-body row justify-content-center">
                      <div
                        class="col-md-7 justify-content-center text-center mb-1"
                      >
                        <v-date-picker v-model="start" />
                      </div>

                      <div class="col-md-5 text-center">
                        <h5>Selected</h5>
                        <p>
                          {{ fD(start) }}
                        </p>

                        <button
                          class="btn btn-outline-primary float-right"
                          data-dismiss="modal"
                        >
                          <span> Use Selected Date </span>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>

    <div class="ng-block" v-if="processing">
      <div class="card card-bordered loading">
        <div class="justify-content-center text-center">
          <div class="spinner-grow text-primary m-3" role="status">
            <span class="sr-only">Processing Data...</span>
          </div>
          <h6 class="mb-4">Processing Data...</h6>
        </div>
      </div>
    </div>

    <div class="ng-block ng-block-lg" v-else>
      <div class="card card-bordered">
        <table class="table table-tranx">
          <thead class="tb-tnx-head">
            <tr class="tb-tnx-item">
              <th class="tb-tnx-info align-middle text-uppercase">
                Description
              </th>
              <th
                class="text-center align-middle text-truncate"
                v-for="c in customers"
                :key="c.id"
              >
                {{ c.name }}
              </th>
              <th class="text-center align-middle text-uppercase">Total</th>
            </tr>
          </thead>

          <tbody>
            <template v-for="(p, i) in varieties" :key="i">
              <tr
                class="tb-tnx-item"
                v-if="form[p.id] && isFilled(form[p.id])"
                data-toggle="collapse"
                :data-target="`#details${p.id}`"
              >
                <td class="tb-tnx-info align-middle">
                  <div class="tb-tnx-desc">
                    <span class="title">
                      {{ p.name }}
                    </span>
                  </div>
                </td>
                <td
                  class="text-center align-middle"
                  v-for="(o, i) in form[p.id]"
                  :key="i"
                >
                  {{ o.toLocaleString(undefined, { maximumFractionDigits: 2, minimumFractionDigits: 2 }) }}
                </td>
                <td class="text-center align-middle">
                  {{ sumTotal(form[p.id]).toLocaleString(undefined, { maximumFractionDigits: 2, minimumFractionDigits: 2 }) }}
                </td>
              </tr>
            </template>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import http from "../../../../common/services/http";
import {
  defineComponent,
  computed,
  ref,
  reactive,
  onMounted,
  watch,
} from "vue";
import moment from "moment";
import router from "../router";
import { format } from "date-fns";

export default defineComponent({
  props: {
    today: {
      type: String,
    },
  },
  setup(props) {
    const form = ref({});

    const varieties = ref([]);
    const customers = ref([]);

    const search = ref("");

    const processing = ref(false);

    const len = ref(3);

    function friday() {
      const date = new Date();
      const last = new Date(new Date().getTime() - 6 * 24 * 60 * 60 * 1000);

      return date.getDay() < 5
        ? new Date(last.setDate(last.getDate() - (last.getDay() - 1) + 4))
        : new Date(date.setDate(date.getDate() - (date.getDay() - 1) + 4));
    }

    const s = props.today ? new Date(props.today) : friday();
    const start = ref(s);

    function fD(d: any, f = "PPPP") {
      return format(d, f);
    }

    async function fetchVarietyOrders(d: any) {
      const res = await http.get(
        `/api/variety-orders?date=${fD(d, "yyyy-MM-dd")}`
      );

      return res.data;
    }

    function fetchVarieties(d: any) {
      processing.value = true;

      fetchVarietyOrders(d).then((av: any) => {
        const os = av.orders;
        customers.value = av.customers;

        len.value = Object.entries(av.customers || { 1: 0 }).length + 1;

        http.get(`/api/varieties?fetch=all`).then((res) => {
          const a = res.data;
          varieties.value = a;

          form.value = a.reduce(
            (r: any, v: any) => ({
              ...r,
              [v.id]: (os as any)[v.id] || 0
            }),
            {}
          );

          processing.value = false;
        });
      });
    }

    function searchVarieties(d: any) {
      fetchVarietyOrders(d).then((av: any) => {
        const os = av.orders;

        const a = varieties.value.filter((v: any) => {
          const name =
            search.value.charAt(0).toUpperCase() + search.value.slice(1);
          return v.name?.startsWith(name);
        });

        varieties.value = a;

        form.value = a.reduce(
          (r: any, v: any) => ({
            ...r,
            [v.id]: (os as any)[v.id] || 0
          }),
          {}
        );
      });
    }

    function setKg(a: any, qty: any) {
      return qty * a.weight;
    }

    function getPercentage(a: any, b: any) {
      return b === 0 ? 0 : Math.round((a / b) * 100);
    }

    function sumTotal(o: any) {
      const data = Object.values(o);

      return data.reduce((a: any, b: any) => {
        return a + parseFloat(b);
      });
    }

    function isFilled(o: any) {
      const data = Object.values(o);

      const filled = data.filter((f: any) => {
        return Number(f) > 0;
      });

      return filled.length > 0;
    }

    onMounted(() => {
      fetchVarieties(start.value);
    });

    watch(
      () => start.value,
      (s) => {
        fetchVarieties(s);
      }
    );

    watch(
      () => start.value,
      (d) => {
        fetchVarieties(d);
      }
    );

    return {
      fetchVarieties,
      processing,
      search,
      searchVarieties,
      varieties,
      customers,
      fD,
      start,
      form,
      setKg,
      getPercentage,
      len,
      sumTotal,
      isFilled,
    };
  },
});
</script>
